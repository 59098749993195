// import About from "./About";
import AdvisorsBackers from "./AdvisorsBackers";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import HelpCenter from "./HelpCenter";
import Home01 from "./Home01";
import Home02 from "./Home02";
import Home03 from "./Home03";
import ComingSoon from "./ComingSoon";
import CollectionComingSoon from "./CollectioncomingSoon";
import Nfts from "./Nfts";
import ParticipantAssets from "./ParticipantAssets";
import Partners from "./Partners";
import RoadMap from "./RoadMap";
import Team from "./Team";
import VisionsMission from "./VisionsMission";
import News from "./News";
import WebUser from "./WebUser"
import AdminUser from "./AdmminUser"
import Claim from "../Claim/Claim";// import Claimed from "../Claim/Claimed";
const routes = [
  //{ path: '/', component: <Home01 />},
  // { path: "/", component: <Home03 /> },
  { path: "/", component: <Home03 /> },
  { path: "/home-v1", component: <Home01 /> },
  { path: "/home-v2", component: <Home02 /> },
  { path: "/home-v3", component: <Home03 /> },
  { path: "/ComingSoon", component: <ComingSoon /> },
  { path: "/CollectionComingSoon", component: <CollectionComingSoon /> },
  { path: "/News", component: <News /> },
  { path: "/nfts", component: <Nfts /> },
  { path: "/blog", component: <Blog /> },
  { path: "/blog-details", component: <BlogDetails /> },
  { path: "/visions-mission", component: <VisionsMission /> },
  { path: "/help-center", component: <HelpCenter /> },
  { path: "/participants-assets", component: <ParticipantAssets /> },
  { path: "/advisors-backers", component: <AdvisorsBackers /> },
  { path: "/partners", component: <Partners /> },
  // { path: "/about", component: <Aboutt /> },
  { path: "/road-map", component: <RoadMap /> },
  { path: "/team", component: <Team /> },
  { path: "/contact", component: <Contact /> },
  // { path: "/claim", component: <Claim /> },
  // this is user route 
  // { path: "/WebUser", component: <WebUser /> },
  // // this is admin route 
  // { rrrpath: "/Adminuser", component: <AdminUser /> },
];

export default routes;
