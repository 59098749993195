import { defineChain } from "viem";

// export const mainnet = defineChain({
//   id: 1,
//   network: "homestead",
//   name: "Ethereum",
//   nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
//   rpcUrls: {
//     alchemy: {
//       http: [
//         "https://eth-mainnet.g.alchemy.com/v2/Nv7ikNStaLxXoSn8U4c0E24KClrlpkzo", // Private RPC node mainnet
//       ],
//       webSocket: ["wss://eth-mainnet.g.alchemy.com/v2"],
//     },
//     infura: {
//       http: ["https://mainnet.infura.io/v3/78133ed82f414d228dfdcf0aa36c5959"],
//       webSocket: ["wss://mainnet.infura.io/ws/v3"],
//     },
//     default: {
//       http: ["https://mainnet.infura.io/v3/78133ed82f414d228dfdcf0aa36c5959"],
//     },
//     public: {
//       http: ["https://cloudflare-eth.com"],
//     },
//   },
//   blockExplorers: {
//     etherscan: {
//       name: "Etherscan",
//       url: "https://etherscan.io",
//     },
//     default: {
//       name: "Etherscan",
//       url: "https://etherscan.io",
//     },
//   },
//   contracts: {
//     ensRegistry: {
//       address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
//     },
//     ensUniversalResolver: {
//       address: "0xc0497E381f536Be9ce14B0dD3817cBcAe57d2F62",
//       blockCreated: 16966585,
//     },
//     multicall3: {
//       address: "0xca11bde05977b3631167028862be2a173976ca11",
//       blockCreated: 14353601,
//     },
//   },
// });

// export const sepolia = defineChain({
//   id: 11_155_111,
//   network: "sepolia",
//   name: "Sepolia",
//   nativeCurrency: { name: "Sepolia Ether", symbol: "SEP", decimals: 18 },
//   rpcUrls: {
//     alchemy: {
//       http: [
//         "https://shape-sepolia.g.alchemy.com/v2/Nv7ikNStaLxXoSn8U4c0E24KClrlpkzo", // Private RPC node testnet
//       ],
//       webSocket: ["wss://eth-sepolia.g.alchemy.com/v2"],
//     },
//     infura: {
//       http: ["https://sepolia.infura.io/v3/78133ed82f414d228dfdcf0aa36c5959"],
//       webSocket: ["wss://sepolia.infura.io/ws/v3"],
//     },
//     default: {
//       http: ["https://sepolia.infura.io/v3/78133ed82f414d228dfdcf0aa36c5959"],
//     },
//     public: {
//       http: ["https://rpc.sepolia.org"],
//     },
//   },
//   blockExplorers: {
//     etherscan: {
//       name: "Etherscan",
//       url: "https://sepolia.etherscan.io",
//     },
//     default: {
//       name: "Etherscan",
//       url: "https://sepolia.etherscan.io",
//     },
//   },
//   contracts: {
//     multicall3: {
//       address: "0xca11bde05977b3631167028862be2a173976ca11",
//       blockCreated: 751532,
//     },
//     ensRegistry: { address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e" },
//     ensUniversalResolver: {
//       address: "0x21B000Fd62a880b2125A61e36a284BB757b76025",
//       blockCreated: 3914906,
//     },
//   },
//   testnet: true,
// });


// export const baseMainnet = defineChain({
//   id: 8453, // Chain ID for Base Mainnet
//   network: "base-mainnet",
//   name: "Base Mainnet",
//   nativeCurrency: {
//     name: "Base Ether",
//     symbol: "ETH", // Native token symbol
//     decimals: 18,
//   },
//   rpcUrls: {
//     alchemy: {
//             http: [
//               "https://base-mainnet.g.alchemy.com/v2/QBQw7BmyGXIFmPqjIVWSZCupUEjjlxmk", // Private RPC node mainnet
//             ],
//             webSocket: ["wss://base-mainnet.g.alchemy.com/v2/QBQw7BmyGXIFmPqjIVWSZCupUEjjlxmk"],
//           },
//           infura: {
//             http: ["https://base-mainnet.infura.io/v3/169fdb674e2b46deac42d8e84a2ae406"],
//             webSocket: ["wss://base-mainnet.infura.io/ws/v3/169fdb674e2b46deac42d8e84a2ae406"],
//           },
//           default: {
//             http: ["https://base-mainnet.infura.io/v3/169fdb674e2b46deac42d8e84a2ae406"],
//           },
//           public: {
//             http: ["https://mainnet.base.org"],
//           },
//   },
//   blockExplorers: {
//     etherscan: {
//       name: "BaseScan",
//       url: "https://basescan.org", // Base block explorer
//     },
//     default: {
//       name: "BaseScan",
//       url: "https://basescan.org",
//     },
//   },
//   contracts: {
//     multicall3: {
//       address: "0xca11bde05977b3631167028862be2a173976ca11", // Multicall3 contract address for Base
//       blockCreated: 1000000, // Update this to the actual block it was deployed at if known
//     },
//   },
// });



// export const baseSepolia = defineChain({
//   id: 84532, // Chain ID for Base Sepolia Testnet
//   network: "base-sepolia",
//   name: "Base Sepolia Testnet",
//   nativeCurrency: {
//     name: "Base Ether",
//     symbol: "ETH", // Native token symbol
//     decimals: 18,
//   },
//   rpcUrls: {
//     alchemy: {
//       http: ["https://base-sepolia.g.alchemy.com/v2/QBQw7BmyGXIFmPqjIVWSZCupUEjjlxmk"],
//       webSocket: ["wss://base-sepolia.g.alchemy.com/v2/QBQw7BmyGXIFmPqjIVWSZCupUEjjlxmk"],
//     },
//     infura: {
//       http: ["https://base-sepolia.infura.io/v3/169fdb674e2b46deac42d8e84a2ae406"],
//       webSocket: ["wss://base-sepolia.infura.io/ws/v3/169fdb674e2b46deac42d8e84a2ae406"],
//     },
//     default: {
//       http: ["https://base-sepolia.infura.io/v3/169fdb674e2b46deac42d8e84a2ae406"],
//     },
//     public: {
//       http: ["https://sepolia.base.org"], // Placeholder for a custom public RPC
//     },
//   },
//   blockExplorers: {
//     etherscan: {
//       name: "BaseScan",
//       url: "https://sepolia.basescan.org", // Updated to Sepolia-specific explorer
//     },
//     default: {
//       name: "BaseScan",
//       url: "https://sepolia.basescan.org",
//     },
//   },
//   contracts: {
//     multicall3: {
//       address: "0xca11bde05977b3631167028862be2a173976ca11",
//       blockCreated: 1000000, // Update this to the actual deployment block number
//     },
//     // Additional custom contracts can be added here
//   },
// });
export const baseMainnet = defineChain({
  id: 8453, // Chain ID for Base Mainnet
  network: "base-mainnet",
  name: "Base Mainnet",
  nativeCurrency: {
    name: "Base Ether",
    symbol: "ETH", // Native token symbol
    decimals: 18,
  },
  rpcUrls: {
    alchemy: {
            http: [
              "https://base-mainnet.g.alchemy.com/v2/QBQw7BmyGXIFmPqjIVWSZCupUEjjlxmk", // Private RPC node mainnet
            ],
            webSocket: ["wss://base-mainnet.g.alchemy.com/v2/QBQw7BmyGXIFmPqjIVWSZCupUEjjlxmk"],
          },
          infura: {
            http: ["https://base-mainnet.infura.io/v3/169fdb674e2b46deac42d8e84a2ae406"],
            webSocket: ["wss://base-mainnet.infura.io/ws/v3/169fdb674e2b46deac42d8e84a2ae406"],
          },
          default: {
            http: ["https://base-mainnet.infura.io/v3/169fdb674e2b46deac42d8e84a2ae406"],
          },
          public: {
            http: ["https://mainnet.base.org"],
          },
  },
  blockExplorers: {
    etherscan: {
      name: "BaseScan",
      url: "https://basescan.org", // Base block explorer
    },
    default: {
      name: "BaseScan",
      url: "https://basescan.org",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11", // Multicall3 contract address for Base
      blockCreated: 1000000, // Update this to the actual block it was deployed at if known
    },
  },
});