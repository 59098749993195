import React, { useState, useEffect } from "react";

import meta from "../assets/images/logo/meta.png"
import walletsd from "../assets/images/logo/wallet.png"
import trust from "../assets/images/logo/trust.png"
import coin from "../assets/images/logo/coin.png"
import { useCallback } from "react";
import { formatEther } from "viem";
import Header from "../components/header/Header";
import { erc20ABI, useAccount, useConnect, useBalance, useConfig } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { waitForTransaction } from "@wagmi/core";
import { ScrollLink } from 'react-scroll';
// import useUserClaimData from "../hooks/contractData"
import { Link } from "react-router-dom";
// import img from "../../assets/images/layouts/deco.png";
// import Toast from "../Toast";
import imgFighter from "../assets/images/layouts/header-image.png";
import { StandardMerkleTree } from "@openzeppelin/merkle-tree";
import Footer3 from "../components/footer/Footer3";

// import { formatUnits, parseUnits } from "@ethersproject/units";

import { writeContract, waitForTransactionReceipt } from "@wagmi/core";

import { Modal, Button } from 'react-bootstrap';
import abi from "../config/abi.json"
import treeJson from "../config/tree.json"
import { CONFIG } from "../config/config";





const initialTimer = 1296000; // 1 hour for example, adjust as needed

const boxStyle = {
  background:
    "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(4px)",
  padding: "12px",
  position: "relative",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  width: "177px",
  height: "102px",
  borderRadius: "20px",
  overflow: "hidden",
  display: "flex", // Added for vertical centering
  flexDirection: "column", // Added for vertical centering
  alignItems: "center", // Added for horizontal centering
  justifyContent: "center", // Added for vertical centering
  margin: "0 5px", // Added for 10px gap between boxes (5px on each side)
};

const numberStyle = {
  color: "#FFF",
  fontSize: "32px",
  fontWeight: 700,
};

const labelStyle = {
  marginTop: "12px",
  //color: "#697783",
  color: "#CED1D3",
  fontSize: "16px",
  fontWeight: 400,
  textTransform: "uppercase", // Added to capitalize the labels
};

// Example countdown values
const countdownValues = {
  days: 10,
  hours: 5,
  minutes: 30,
  seconds: 15,
};

const headingBoxStyle = {
  background:
    "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(2px)",
  borderRadius: "20px",
  display: "flex", // For horizontal alignment
  justifyContent: "center", // For horizontal alignment
  alignItems: "center", // For vertical alignment
  padding: "12px", // Optional, adjust to your liking
  marginBottom: "20px", // Optional, for spacing between heading and boxes
  width: "70%",
  // margin:'-90px'
  textAlign:"center"

};
const headingBoxStyle2 = {
  background:
    "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(2px)",
  borderRadius: "20px",
  display: "flex", // For horizontal alignment
  justifyContent: "center", // For horizontal alignment
  alignItems: "center", // For vertical alignment
  marginBottom: "20px", // Optional, for spacing between heading and boxes
  width: "100%",
  height:'30px',
  color: "#FFF", // Assuming white, adjust as per your theme
  fontSize: "18px", // Adjust as per your preference
  fontWeight: 600, // Adjust as per your preference
  textTransform: "uppercase", 
  boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"
};
const isMobile = window.innerWidth <= 768;
const headingTextStyle = {
  background:
  "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(2px)",
  // Since you're using <h4>, these styles are optional. Adjust as needed.
  color: "#FFF", // Assuming white, adjust as per your theme
  fontSize: isMobile ? "5px" : "20px",
  fontWeight: 600, // Adjust as per your preference
  textTransform: "uppercase", // If you want the title in uppercase
  textAlign:"center",
  alignItems:"center",
  borderRadius: "20px",
  marginLeft:"15px"
};

const containerStyle = {
  display: "flex",
  flexDirection: "column", // Stack elements vertically
  alignItems: "center", // Center horizontally
  width: "100%",
};


const countdownStyle = {
  marginBottom: "10px", // Space between countdown and inputs
  marginTop: "10px",
  fontSize: isMobile ? "25px" : "25px", // Adjust font size based on screen width
  fontWeight: "600",
  textAlign: "left",
  color: "#fff",
};

const formContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const inputBoxStyle = {
  // Your existing inputBoxStyle
  background: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(2px)",
  borderRadius: "10px",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  font: "300 14px/1.5 sans-serif",
  color: "#fff",
  padding: "15px",
  boxShadow: "none",
  flex: 1,
  height: "54px",
  marginRight: "5px", // Gap between inputs
  placeholder: { color: "#fff" }, // Set placeholder color to white
};

const inputBoxStyleLast = {
  ...inputBoxStyle, // Inherit all styles from inputBoxStyle
  marginRight: "0px", // Reset margin for the last input
};



function Claim(props) {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  
    // Set target date (1st January 2025)
    const [show, setShow] = useState(false); // State to control modal visibility

    const handleClose = () => setShow(false); // Close the modal
    const handleShow = () => setShow(true); // Open the modal

    const [show2, setShow2] = useState(false); // State to control modal visibility

    const handleClose2 = () => setShow2(false); // Close the modal
    const handleShow2 = () => setShow2(true); // Open the modal
    const targetDate = new Date("2025-01-01T00:00:00");

    // Get the current date and time
    const now = new Date();
  
    // Calculate the difference between now and the target date
    const initialTimer = Math.floor((targetDate - now) / 1000); // in seconds
  
    // Initialize countdown values
    const [countdownValues, setCountdownValues] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
  
    const [timer, setTimer] = useState(initialTimer);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1); // Decrease timer by 1 second
          updateCountdownValues(timer - 1);
        } else {
          clearInterval(intervalId); // Stop the countdown when the timer reaches 0
        }
      }, 1000); // Update every 1 second
  
      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [timer]);
  
    const updateCountdownValues = (timeLeft) => {
      const days = Math.floor(timeLeft / (60 * 60 * 24)); // Calculate days
      const hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60)); // Calculate hours
      const minutes = Math.floor((timeLeft % (60 * 60)) / 60); // Calculate minutes
      const seconds = timeLeft % 60; // Calculate seconds
  
      setCountdownValues({ days, hours, minutes, seconds });
    };
  
    useEffect(() => {
      updateCountdownValues(timer); // Initialize countdown values when component mounts
    }, []); 

  const handleOpenUsers = () => {
    // Replace 'example.pdf' with the path to your PDF file
    // Create a new window (popup)
   window.open("https://app.respecttoken.com", "_blank");

    // Set the content of the new window to display the PDF inside an iframe
   // Close the document stream to render itttt
  };

  
  const [userBalance,setUserBalance] = useState(0)
  const [totalPurchaseAmount, setTotalPurchaseAmount] = useState(0) 
  // const {claimData, refetch} = useUserClaimData()
  const [hasClaimed, setHasClaimed] = useState(false);
  // console.log(claimData,"hunain")

  const [loading, setLoading] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showIneligibilityModal, setShowIneligibilityModal] = useState(false);
  const [error,setError] = useState(false)
  const handleVerifyClose = () => setShowVerifyModal(false);
  const handleVerifyShow = () => setShowVerifyModal(true);

  const handleConfirmClose = () =>{
     setShowConfirmationModal(false);
     setIsChecked(false)
    }
  const handleConfirmShow = () =>{ setShowConfirmationModal(true)
    setShowVerifyModal(false)};

  const handleIneligibilityClose = () => setShowIneligibilityModal(false);
  const handleIneligibilityShow = () => {
    setShowIneligibilityModal(true) 
    setShowVerifyModal(false)
  };
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showToast = (msg, type) => {
    return setAlertState({
      open: true,
      message: msg,
      severity: type,
    });
  };
  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { data, isError, isLoading } = useBalance({
    address: address,
  });
  const config = useConfig();
  
  const connectionHandler = React.useCallback(() => {
    setIsChecked(false)
    setShowConfirmationModal(false)
    if (address) {
      return open();
     
    }
    open();
    
  }, [address, open]);


//   const handleClaim = async () => {
//     if (!isConnected) {
//       return;
//     }
//     try {
//       setLoading(true);
//       const tree = StandardMerkleTree.load(treeJson);
//       let proof;
//       let amount;
//       for (const [i, v] of tree.entries()) {
//         if (v[0] === address) {
//           proof = tree.getProof(i);
//           amount = v[1];
//           console.log(proof)
//           console.log(amount)
//         }
//       }
  
//       const tx = await writeContract( {
//         address: "0x2C1a5209D02E28f989Cf5D8663A3d1199b29E5CA",
//         abi: abi,
//         functionName: "Claim",
//         args: [amount, proof],
//       });
//       console.log(tx);
  
//       // Use waitForTransaction instead of waitForTransactionReceipt
//       const result = await waitForTransaction({
//         hash: tx, // In v1, we pass the hash directly
//         confirmations: 1, // Optional: number of confirmations to wait for
//       });
      
//       console.log(result);
//       setLoading(false);
//       setUserBalance(0)
//       setHasClaimed(true);
//       refetch()
//       handleShow()
  
//     } catch (e) {
//       console.log(e);
//       setLoading(false);
//       handleShow2()
//     }
//   };
  

//   useEffect(() => {
//     if(isConnected){
//         const tree = StandardMerkleTree.load(treeJson);
//         let totalTokens = 0;
//         for (const [i, v] of tree.entries()) {
//             if (v[0] === address) {
//                 totalTokens = v[1]
//             }
//         }
        
//         setTotalPurchaseAmount(formatEther(totalTokens))

        
//     }
// }, [address, isConnected])
  
  

  

  
 
 

  return (
    <>
       {/* <Button variant="primary" onClick={handleShow2}>
        Show Transaction Success
      </Button> */}
{/* // modal box  */}
  {/* Modal Box */}
  <Modal show={show} onHide={handleClose}>
        <Modal.Header 
          closeButton 
          style={{
            background: 'linear-gradient(264.28deg,rgb(0, 0, 0) -38.2%, #5C27FE 103.12%)',
            color: 'white',
            fontSize: '16px' // Smaller font size for the header
          }}
        >
          <Modal.Title style={{fontSize:"1.5rem",fontWeight:'bolder'}}>Transaction Alert! 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body 
          style={{
            background: 'linear-gradient(264.28deg,rgb(0, 0, 0) -38.2%, #5C27FE 103.12%)',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)' // Black shade with transparency for overlay effect
          }}
        >
          Congratulations, you have successfully claimed your Respect Tokens!
        </Modal.Body>
        <Modal.Footer 
          style={{
            background: 'linear-gradient(264.28deg,rgb(0, 0, 0) -38.2%, #5C27FE 103.12%)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)' // Black shade with transparency
          }}
        >
          <Button style={{background:'black',color:'white',border:'none'}} onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
       {/* Add 
       custom styles for the close button to make it white */}
       <Modal show={show2} onHide={handleClose2}>
        <Modal.Header 
          closeButton 
          style={{
            background: 'linear-gradient(264.28deg,rgb(0, 0, 0) -38.2%, #5C27FE 103.12%)',
            color: 'white',
            fontSize: '16px' // Smaller font size for the header
          }}
        >
          <Modal.Title style={{fontSize:"1.5rem",fontWeight:'bolder'}}>Funds Alert!

          </Modal.Title>
        </Modal.Header>
        <Modal.Body 
          style={{
            background: 'linear-gradient(264.28deg,rgb(0, 0, 0) -38.2%, #5C27FE 103.12%)',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)' // Black shade with transparency for overlay effect
          }}
        >
         
          You are not eligible for claim!
        </Modal.Body>
        <Modal.Footer 
          style={{
            background: 'linear-gradient(264.28deg,rgb(0, 0, 0) -38.2%, #5C27FE 103.12%)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)' // Black shade with transparency
          }}
        >
          <Button style={{background:'black',color:'white',border:'none'}} onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
       <style>
        {`
          .modal-header .btn-close {
            color: white !important; /* Make the cross icon white */
            background: none; /* Remove the default background */
          }
        `}
      </style>

      <Header />
    <section className="banner s3">
      {/* <div className="shape"></div>
      <div className="shape right"></div>
      <div className="shape s3 right"></div> */}
   
      <div className="container big">
        
        <div className="col">
          
          <div className="col-xl-7 col-md-12 m-auto">
          
            <div className="banner__right" style={{ padding: "0"}}>
            {/* <div style={headingTextStyle}>
                  <h5 style={{paddingTop:"10px",paddingBottom:"10px",textAlign:"center",paddingLeft:"15px"}}>
                  “You Can Claim Your Tokens At The End of Presale 10”
                    </h5>
                    </div> */}
              <div className="image-1">
                <img src={imgFighter} width={800} alt="Cyfonii" />
              </div>
              
              
              
              <div
                className="banner-box"
                data-aos="fade-up"
                data-aos-duration="2000"
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "330px",
                  backdropFilter: "none",
                }}
              >
                {/* <div
                  className="banner-box"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <h4 className="title">Next Price Increase</h4>
                </div> */}

                <div style={headingTextStyle}>
                  <h5 style={{paddingTop:"10px",paddingBottom:"10px",textAlign:"center",paddingLeft:"15px"}}>
                  “You Can Claim Your Tokens At The End of Presale 10”
                    </h5>
                    </div>

                <div className="top">
                  {/* <h4 className="title">Ninja 3D Digital Artwork</h4> */}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      borderBottom:
                        "border-bottom: 1px solid rgba(255, 255, 255, 0.1);",
                    }}
                  >
                    {/* <div id="mrd" style={headingBoxStyle}>
                      <h4 style={headingTextStyle} className="title">
                        Next phase pricing =  ${nextPrice.toFixed(3)}
                      </h4>
                      
                    </div> */}
                    
                    {/* <div style={headingBoxStyle2}>
                      
                      <ProgressBar
        now={progress} // Set the progress value
        // label={`${Math.round(progress)}%`} // Show percentage label insideeeEE
        variant="blue"
        className="w-100 h-100"
        style={{ height: '100px',margin:'0',borderRadius:'20px',boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset" }} // You can customize the color if you like
      />
     
     <h4 style={{position:'fixed',fontSize:'15px',color:'black'}} className="title">
     {((usdtToRaised / usdtTotalRaised) * 100).toFixed(2)} %
                      </h4>   
                      
                    </div> */}
                    <div className="done">
                      {/* Container for the boxes */}
                      <div className="countdown-box bnm" style={{height:'0px',background:'none',opacity:'0'}}>
                        {/* <span className="mn" style={numberStyle}>00</span>
                        <span className="mn" style={labelStyle}>DAY(S)</span> */}
                      </div>
                      <div className="countdown-box bnm" style={{height:'0px',background:'none',opacity:'0'}}>
                        {/* <span className="mn" style={numberStyle}>00</span>
                        <span className="mn" style={labelStyle}>HOUR(S)</span> */}
                      </div>
                      <div className="countdown-box bnm" style={{height:'0px',background:'none',opacity:'0'}}>
                        {/* <span className="mn" style={numberStyle}>
                          00
                        </span>
                        <span className="mn" style={labelStyle}>MINUTE(S)</span> */}
                      </div>
                      <div className="countdown-box bnm" style={{height:'0px',opacity:'0'}}>
                        {/* <span className="mn" style={numberStyle}>
                          00
                        </span>
                        <span className="mn" style={labelStyle}>SECOND(S)</span> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="wishlist">
                    <div className="icon">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.25 3.8125C11.25 2.36292 10.0256 1.1875 8.51533 1.1875C7.38658 1.1875 6.41708 1.84433 6 2.78175C5.58292 1.84433 4.61342 1.1875 3.48408 1.1875C1.975 1.1875 0.75 2.36292 0.75 3.8125C0.75 8.02417 6 10.8125 6 10.8125C6 10.8125 11.25 8.02417 11.25 3.8125Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p>3432</p>
                  </div> */}
                </div>

                <div className="main">
                  {/* <div className="info" style={{ width: "37%" }}>
                  <h1 className="  font-bold font-sans-serif ">
                      Total Purchase
                    </h1>
                    <p> */}
                      {/* {claimData?.userInitialized ? (
                                    new Intl.NumberFormat('en-us').format(claimData.userBalance)
                                ) : (
                                    new Intl.NumberFormat('en-us').format(totalPurchaseAmount)
                                )} &nbsp; RESPECT */}

                            {/* {hasClaimed ? 0 : totalPurchaseAmount} RESPECT
                    </p>
                  </div> */}

                  <div style={containerStyle}>
                    {/* <div style={countdownStyle} className="coundown">
                      <p style={{ color: "#fff" ,textAlign:"center"}}>
                        TOKENS CLAIMED:  <br /> 0 RESPECT TOKENS
                      
                       {/* {claimData?.userInitialized ? (
                                     new Intl.NumberFormat('en-us').format(claimData.tokensClaimed)
                                ) : (
                                         0 
                                )}  RESPECT TOKENS */}
                        {/* / $
                        {usdtTotalRaised.toFixed(2)} */}
                      {/* </p>
                      <span
                        className="js-countdown"
                        data-timer="12865550"
                      ></span>
                    </div> */} 

                    <div style={formContainerStyle}>
                      
                      {/* <divvv className="flex flex-col">
                      <div className='text-sm text-light-white'>Enter the Amount you want to claim</div>
                      <div style={inputBoxStyle}>
                        
                      <input
                        type="text"
                        className="input-placeholder"
                        placeholder="Amount you want to claim"
                        value={amount}
                        required
                        onChange={onAmountChange}
                        style={{
                          width: "100%",
                          background: "transparent",
                          border: "none",
                          outline: "none",
                          color: "#fff",
                        }}
                      />
                    </div></div> */}
                    
                    </div>

                 
                  </div>
                </div>
                    
              {/* {address ? (
  <div className="button">
    <Link
      to="#"
      className="action-btn"
      data-toggle="modal"
      data-target="#popup_bid"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span>
        Claim
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5 16.5L19 9M19 9L11.5 1.5M19 9H1"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </Link>
  </div>
) : (
  <div className="button" style={{marginLeft:"none"}} onClick={connectionHandler}>
    <Link
      to="#"
      className="action-btn"
      data-toggle="modal"
      data-target="#popup_bid"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span>
        Connect Wallet
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5 16.5L19 9M19 9L11.5 1.5M19 9H1"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </Link>
  </div>
)} */}

              {address && (
                <>
                  <div
                    onClick={connectionHandler}
                    className="button"
                    // style={{
                    //   border: "1px solid #B5B9BE",
                    //   color: "#000",
                    //   fontWeight: "600",
                    //   py: 1,
                    //   width: "100%",
                    // }}
                  >
                    <Link
                    to="#"
                    className="action-btn"
                    data-toggle="modal"
                    data-target="#popup_bid"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span>
                      Disconnect Wallet{" "}
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 16.5L19 9M19 9L11.5 1.5M19 9H1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                    {/* {address.slice(0, 5)} ... {address.slice(-5)} */}
                  </div>
                </>
              )}
              
                {/* <div className="button">
                  <Link
                    to="#"
                    className="action-btn"
                    data-toggle="modal"
                    data-target="#popup_bid"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span>
                      Connect Wallet{" "}
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 16.5L19 9M19 9L11.5 1.5M19 9H1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-xl-12 col-md-12" style={{textAlign:'center',width:'100%'}}>
            <div className="banner__left">
              <div className="block-text">
                <h4 className="heading" style={{textAlign:"center", marginTop:"10px"}}>
                  CLAIM YOUR RESPECT TOKENS{" "}
                  <span className="arlo_tm_animation_text_word"></span>
                </h4>
                <p className="desc">
              
                </p>
                <h5 className="heading">
                  {" "}
                  <span className="arlo_tm_animation_text_word" style={{fontSize:'2rem'}}>
                  Connect Your Wallet To Claim Respect Tokens{" "}
                  </span>
                </h5>
                <h5 className="">
                  {" "}
                  <span className="arlo_tm_animation_text_word">
                  Step-by-step guide of claiming RESPECT Token{" "}
                  </span>
                </h5>
                <p className="desc">
                  <br />
                <span style={{fontWeight:"bold"}}>Step 1.
                <br />
                Connect to your MetaMask
                </span>
                <hr />
                
                In order to claim your RESPECT Token, you need to connect your MetaMask wallet.
                  <br />
                <br />
                If you are using a mobile phone:
                <br />
                1. Click on the 'Browser' icon at the bottom of the MetaMask app.
                <br />
                2. Copy-paste the claiming website address into the input field: https://respecttoken.com/claim
                <br />
                <br />
                If you are using a desktop:
                <br />
                Make sure your MetaMask browser extension is the only enabled wallet extension.
                </p>

                <p className="desc">
                <span style={{fontWeight:"bold"}}  > 
                Step 2.
                <br />
                Choose the address
                </span>
                <hr />
                
                Choose the Base network and right address from which you buy RESPECT Token previously.
                </p>

                <p className="desc">
                <span style={{fontWeight:"bold"}}  >   
                Step 3.
                <br />
                Claim your RESPECT Tokens
                </span>
                <hr />
                
                If you have chosen the right network and right address in the previous steps, then you can claim with that specific address.
                <br />
                Click on the “Claim" button.
                </p>

                
                <p className="desc"> 
                <span style={{fontWeight:"bold"}}  > 
                Step 4.
                <br />
                Confirm and pay the gas fee
                </span>
                <hr />
                In MetaMask popup click “Confirm” to sign the transaction to get your RESPECT Token.
                
                </p>


                {/* <Link    onClick={handleConfirmShow} className="action-btn">
                  <span>Claim RESPECT</span>
                </Link> */}
              </div>

             
            </div>
          </div>
        </div>
      </div>

      
      </section>
      <Footer3 />
    </>
  );
}

export default Claim;
