import React, { useState, useEffect } from "react";
import USDC from "../../assets/images/logo/usdcc.png";
import Ethereumlogo from "../../assets/img/logo/Ethereumlogo.png";
import meta from "../../assets/images/logo/meta.png"
import walletsd from "../../assets/images/logo/wallet.png"
import { ProgressBar } from 'react-bootstrap';
import trust from "../../assets/images/logo/trust.png"
import coin from "../../assets/images/logo/coin.png"
import { switchChain } from '@wagmi/core'
import { useCallback } from "react";
import { erc20ABI, useAccount, useConnect, useBalance } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { waitForTransaction } from "@wagmi/core";
import { ScrollLink } from 'react-scroll';

import { Link } from "react-router-dom";
import img from "../../assets/images/layouts/deco.png";
import Toast from "../Toast";
import imgFighter from "../../assets/images/layouts/header-image.png";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  usdtReadFunction,
  usdtWriteFunction,
} from "../../ConnectivityAssets/hooks";
import { formatUnits, parseUnits } from "@ethersproject/units";
import {
  preSaleAddress,
  usdtAddress,
} from "../../ConnectivityAssets/environment";
import { writeContract, readContract } from "@wagmi/core";
import { Modal, Button } from 'react-bootstrap';





const initialTimer = 1296000; // 1 hour for example, adjust as needed

const boxStyle = {
  background:
    "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(4px)",
  padding: "12px",
  position: "relative",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  width: "177px",
  height: "102px",
  borderRadius: "20px",
  overflow: "hidden",
  display: "flex", // Added for vertical centering
  flexDirection: "column", // Added for vertical centering
  alignItems: "center", // Added for horizontal centering
  justifyContent: "center", // Added for vertical centering
  margin: "0 5px", // Added for 10px gap between boxes (5px on each side)
};

const numberStyle = {
  color: "#FFF",
  fontSize: "32px",
  fontWeight: 700,
};

const labelStyle = {
  marginTop: "12px",
  //color: "#697783",
  color: "#CED1D3",
  fontSize: "16px",
  fontWeight: 400,
  textTransform: "uppercase", // Added to capitalize the labels
};

// Example countdown values
const countdownValues = {
  days: 10,
  hours: 5,
  minutes: 30,
  seconds: 15,
};

const headingBoxStyle = {
  background:
    "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(2px)",
  borderRadius: "20px",
  display: "flex", // For horizontal alignment
  justifyContent: "center", // For horizontal alignment
  alignItems: "center", // For vertical alignment
  padding: "12px", // Optional, adjust to your liking
  marginBottom: "20px", // Optional, for spacing between heading and boxes
  width: "70%",
  // margin:'-90px'
  textAlign:"center"

};
const headingBoxStyle2 = {
  background:
    "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(2px)",
  borderRadius: "20px",
  display: "flex", // For horizontal alignment
  justifyContent: "center", // For horizontal alignment
  alignItems: "center", // For vertical alignment
  marginBottom: "20px", // Optional, for spacing between heading and boxes
  width: "100%",
  height:'30px',
  color: "#FFF", // Assuming white, adjust as per your theme
  fontSize: "18px", // Adjust as per your preference
  fontWeight: 600, // Adjust as per your preference
  textTransform: "uppercase", 
  boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"
};
const isMobile = window.innerWidth <= 768;
const headingTextStyle = {
  background:
  "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(2px)",
  // Since you're using <h4>, these styles are optional. Adjust as needed.
  color: "#FFF", // Assuming white, adjust as per your theme
  fontSize: isMobile ? "5px" : "20px",
  fontWeight: 600, // Adjust as per your preference
  textTransform: "uppercase", // If you want the title in uppercase
  textAlign:"center",
  alignItems:"center",
  borderRadius: "20px",
  marginLeft:"15px"
};

const containerStyle = {
  display: "flex",
  flexDirection: "column", // Stack elements vertically
  alignItems: "center", // Center horizontally
  width: "100%",
};


const countdownStyle = {
  marginBottom: "10px", // Space between countdown and inputs
  marginTop: "10px",
  fontSize: isMobile ? "20px" : "25px", // Adjust font size based on screen width
  fontWeight: "600",
  textAlign: "left",
  color: "#fff",
};

const formContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const inputBoxStyle = {
  // Your existing inputBoxStyle
  background: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(2px)",
  borderRadius: "10px",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  font: "300 14px/1.5 sans-serif",
  color: "#fff",
  padding: "15px",
  boxShadow: "none",
  flex: 1,
  height: "54px",
  marginRight: "5px", // Gap between inputs
  placeholder: { color: "#fff" }, // Set placeholder color to white
};

const inputBoxStyleLast = {
  ...inputBoxStyle, // Inherit all styles from inputBoxStyle
  marginRight: "0px", // Reset margin for the last input
};

function Banner3(props) {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  
    // Set target date (1st January 2025)
    const [show, setShow] = useState(false); // State to control modal visibility

    const handleClose = () => setShow(false); // Close the modal
    const handleShow = () => setShow(true); // Open the modal

    const [show2, setShow2] = useState(false); // State to control modal visibility

    const handleClose2 = () => setShow2(false); // Close the modal
    const handleShow2 = () => setShow2(true); // Open the modal
    const targetDate = new Date("2025-01-01T00:00:00");

    // Get the current date and time
    const now = new Date();
  
    // Calculate the difference between now and the target date
    const initialTimer = Math.floor((targetDate - now) / 1000); // in seconds
  
    // Initialize countdown values
    const [countdownValues, setCountdownValues] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
  
    const [timer, setTimer] = useState(initialTimer);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1); // Decrease timer by 1 second
          updateCountdownValues(timer - 1);
        } else {
          clearInterval(intervalId); // Stop the countdown when the timer reaches 0
        }
      }, 1000); // Update every 1 second
  
      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [timer]);
  
    const updateCountdownValues = (timeLeft) => {
      const days = Math.floor(timeLeft / (60 * 60 * 24)); // Calculate days
      const hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60)); // Calculate hours
      const minutes = Math.floor((timeLeft % (60 * 60)) / 60); // Calculate minutes
      const seconds = timeLeft % 60; // Calculate seconds
  
      setCountdownValues({ days, hours, minutes, seconds });
    };
  
    useEffect(() => {
      updateCountdownValues(timer); // Initialize countdown values when component mounts
    }, []); 

  const handleOpenUsers = () => {
    // Replace 'example.pdf' with the path to your PDF file
    // Create a new window (popup)
   window.open("https://app.respecttoken.com", "_blank");

    // Set the content of the new window to display the PDF inside an iframe
   // Close the document stream to render itttt
  };

  
  const [currentPrice, setCurrentPrice] = useState(0);
  const [nextPrice, setNextPrice] = useState(0);
  const [sold, setSold] = useState(0);
  const [usdtToRaised, setUsdtToRaised] = useState(0);
  const [usdtTotalRaised, setUsdtTotalRaised] = useState(0);
  const [transectionData, setTransectionData] = useState(null);
  const [amount, setAmount] = useState(0);
  const [presaleId, setPresaleId] = useState(1);
  const [youWillGet, setYouWillGet] = useState(0);
  const [minimumButToken, setMinimumBuyToken] = useState(0);
  const [canbuy, setCanBuy] = useState(null);
  const [Allownce, setAllownce] = useState(0);
  const [activeCurrencry, setActiveCurrency] = useState("ETH");
  const [loading, setLoading] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showIneligibilityModal, setShowIneligibilityModal] = useState(false);
  const [error,setError] = useState(false)
  const handleVerifyClose = () => setShowVerifyModal(false);
  const handleVerifyShow = () => setShowVerifyModal(true);

  const handleConfirmClose = () =>{
     setShowConfirmationModal(false);
     setIsChecked(false)
    }
  const handleConfirmShow = () =>{ setShowConfirmationModal(true)
    setShowVerifyModal(false)};

  const handleIneligibilityClose = () => setShowIneligibilityModal(false);
  const handleIneligibilityShow = () => {
    setShowIneligibilityModal(true) 
    setShowVerifyModal(false)
  };
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showToast = (msg, type) => {
    return setAlertState({
      open: true,
      message: msg,
      severity: type,
    });
  };
  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { data, isError, isLoading } = useBalance({
    address: address,
  });
  
  const connectionHandler = React.useCallback(() => {
    setIsChecked(false)
    setShowConfirmationModal(false)
    if (address) {
      return open();
     
    }
    open();
    
  }, [address, open]);


  const init = useCallback(async () => {
    try {
      // setLoading(true);

      const id = await preSaleReadFunction("presaleId");

      setPresaleId(id);
      const presaleData = await preSaleReadFunction("presale", [id]);

      const cPrice = 1 / Number(formatUnits(presaleData[2], 18));
      setCurrentPrice(cPrice);
      console.log(currentPrice,"hunain")

      const nPrice = 1 / Number(formatUnits(presaleData[3], 18));
      setNextPrice(nPrice);
      console.log(nPrice,"hunainn")

      const soldtoken = Number(formatUnits(presaleData[4], 18));
      setSold(soldtoken);
      let total_raised;
      const usdtRaise = Number(formatUnits(presaleData[7], 6));
      total_raised = usdtRaise + 38390;
      setUsdtToRaised(total_raised);

      const totalRaised = Number(formatUnits(presaleData[6], 6));
      setUsdtTotalRaised(totalRaised);

      // Minimum Buy tokennn
      const minumBuyT = await preSaleReadFunction("MinTokenTobuy");
      setMinimumBuyToken(Number(formatUnits(minumBuyT, 18)));
      setPresaleId(id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      /* empty */
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const fetchAllowance = useCallback(async () => {
    try {
      const allow = await readContract({
        address: usdtAddress,
        abi: erc20ABI,
        functionName: "allowance",
        args: [address, preSaleAddress],
      });

      setAllownce(formatUnits(allow, 6));
    } catch (error) {
      console.error("Failed to fetch allowance", error);
    }
  }, [address]);

  useEffect(() => {
    const updateCanBuy = () => {
      if (Number(Allownce) >= Number(amount)) {
        setCanBuy(true);
      } else {
        setCanBuy(false);
      }
    };
    updateCanBuy();
  }, [Allownce, amount]);

  // Onchange Function
  const onAmountChange = async (e) => {
    try {
      const inputAmount = e.target.value;
      setAmount(inputAmount);

      if (
        inputAmount === "" ||
        inputAmount === "0" ||
        isNaN(Number(inputAmount))
      ) {
        setYouWillGet(0);
        setLoading(false);
        return;
      }

      if (activeCurrencry === "ETH") {
        const ethToToken = await preSaleReadFunction("ethToTokens", [
          presaleId,
          parseUnits(inputAmount).toString(),
        ]);

        setYouWillGet(Number(formatUnits(ethToToken, 18)));
      } else {
        const usdtToToken = await preSaleReadFunction("usdtToTokens", [
          presaleId,
          parseUnits(inputAmount, 6).toString(),
        ]);

        setYouWillGet(Number(formatUnits(usdtToToken, 18)));
      }
    } catch (error) {
      setLoading(false);
      showToast("Error during Transection !", "error");
      console.log("Error ....", error);
    }
  };

  const approveUSDT = async () => {
    setLoading(true);
    try {
      const { hash } = await writeContract({
        address: usdtAddress,
        abi: erc20ABI,
        functionName: "approve",
        args: [preSaleAddress, parseUnits(amount, 6).toString()],
      });
      const receipt = await waitForTransaction({ hash });
      if (hash && receipt) {
        await fetchAllowance();
        showToast(`${amount} USDT Approve`);
      } else {
        throw new Error("Approval transaction failed");
      }
    } catch (error) {
      console.error("Approval failed", error);
      showToast("Approval failed: " + error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (address) {
      fetchAllowance();
    }
  }, [address, fetchAllowance]);

  // Add Transection Function

  // Function to save data to Google Sheet
  const saveData = async (responce) => {
    console.log("respnce.....", responce);
    const date = new Date().toISOString();
    const data = [
      date,
      address,
      responce.transactionHash,
      amount,
      activeCurrencry,
      youWillGet,
    ];
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycby8bpalfahYfZDKHrmzSVmbFrV7ZhDbEBaQa2Y1uTVEJINxqpyimaGLc0Pw0_LfgdgA7A/exec",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      const result = await response.text();
      console.log(result);
      setAmount(0);
      setYouWillGet(0);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // Buy Tokens

  const buyHandler = async (e) => {
    e.preventDefault();
    // validation
    if (isNaN(Number(amount))) {
      showToast("Invalid Input !", "error");
      return;
    }
    if (Number(youWillGet) < minimumButToken) {
      showToast(
        `The minimum ammount to buy  is ${minimumButToken}RESPECT.`,
        "error"
      );
      return;
    }
    try {
      setLoading(true);
      // buy with ETH

      if (activeCurrencry === "ETH") {
        // Call the function to buy with ETH
        
          const res = await preSaleWriteFunction(
            "buyWithEth",
            [],
            parseUnits(amount, 18).toString()
          );
          saveData(res);
          setAmount(0);
          setYouWillGet(0);
          setLoading(false);
          handleShow()
        

        // setTransectionhash(res);
        // setOpenD(true);
      } else {
        // buy with USDT

        const res = await preSaleWriteFunction("buyWithUSDT", [
          parseUnits(amount, 6).toString(),
        ]);
        saveData(res);
        setAmount(0);
        setYouWillGet(0);
        handleShow()
        // setTransectionhash(res);
        // setOpenD(true);
      }

      init();
      setLoading(false);
    } catch (error) {
      if (error.message.includes("account does not have enough funds")) {
        // setError(true);  // Set the error state
        handleShow2() // Show the toast with error message
      } else {
        // showToast(error.message, "error");
      }
  
      console.log("Error ...", error);
    }

    /// buy start

  };

  
 
  const progress = (usdtToRaised / usdtTotalRaised) * 100;

  return (
    <>
       {/* <Button variant="primary" onClick={handleShow2}>
        Show Transaction Success
      </Button> */}
{/* // modal box  */}
  {/* Modal Box */}
  <Modal show={show} onHide={handleClose}>
        <Modal.Header 
          closeButton 
          style={{
            background: 'linear-gradient(264.28deg,rgb(0, 0, 0) -38.2%, #5C27FE 103.12%)',
            color: 'white',
            fontSize: '16px' // Smaller font size for the header
          }}
        >
          <Modal.Title style={{fontSize:"1.5rem",fontWeight:'bolder'}}>Transaction Alert! 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body 
          style={{
            background: 'linear-gradient(264.28deg,rgb(0, 0, 0) -38.2%, #5C27FE 103.12%)',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)' // Black shade with transparency for overlay effect
          }}
        >
          Congratulations, your transaction has completed Successfully!
        </Modal.Body>
        <Modal.Footer 
          style={{
            background: 'linear-gradient(264.28deg,rgb(0, 0, 0) -38.2%, #5C27FE 103.12%)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)' // Black shade with transparency
          }}
        >
          <Button style={{background:'black',color:'white',border:'none'}} onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
       {/* Add 
       custom styles for the close button to make it white */}
       <Modal show={show2} onHide={handleClose2}>
        <Modal.Header 
          closeButton 
          style={{
            background: 'linear-gradient(264.28deg,rgb(0, 0, 0) -38.2%, #5C27FE 103.12%)',
            color: 'white',
            fontSize: '16px' // Smaller font size for the header
          }}
        >
          <Modal.Title style={{fontSize:"1.5rem",fontWeight:'bolder'}}>Funds Alert!

          </Modal.Title>
        </Modal.Header>
        <Modal.Body 
          style={{
            background: 'linear-gradient(264.28deg,rgb(0, 0, 0) -38.2%, #5C27FE 103.12%)',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)' // Black shade with transparency for overlay effect
          }}
        >
         
You don’t have sufficient funds available to make this transactions!
        </Modal.Body>
        <Modal.Footer 
          style={{
            background: 'linear-gradient(264.28deg,rgb(0, 0, 0) -38.2%, #5C27FE 103.12%)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)' // Black shade with transparency
          }}
        >
          <Button style={{background:'black',color:'white',border:'none'}} onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
       <style>
        {`
          .modal-header .btn-close {
            color: white !important; /* Make the cross icon white */
            background: none; /* Remove the default background */
          }
        `}
      </style>
    <section className="banner s3">
      <div className="shape"></div>
      <div className="shape right"></div>
      <div className="shape s3 right"></div>

      <div className="container big">
        <div className="row">
          <div className="col-xl-5 col-md-12">
            <div className="banner__left bnb">
              <div className="block-text">
                <h4 className="heading">
                  Empowering the Taekwondo and Sports Community{" "}
                  <span className="arlo_tm_animation_text_word"></span>
                </h4>
                <p className="desc">
                Introducing Respect Token, a groundbreaking initiative designed to empower and reward the Taekwondo 
                and sports community. Built to honor the values of discipline, respect, and excellence, Respect Token 
                represents unity and growth, bringing together practitioners, fans, and enthusiasts in a shared vision of 
                progress and connection.
                </p>
                <h5 className="heading">
                  {" "}
                  <span className="arlo_tm_animation_text_word">
                  As Featured on ESPN Sports Network{" "}
                  </span>
                </h5>
                <p className="desc">
                Respect Token is making headlines across the globe, proudly featured on the renowned ESPN Sports 
                Network. This recognition highlights our mission to uplift and connect the worldwide Taekwondo 
                community, celebrating the values of discipline, respect, and excellence that define martial arts.
                <br />
                <br />
                Through its feature on ESPN, Respect Token is showcased as a pioneering initiative that unites 
                practitioners, fans, and enthusiasts on a global scale. It’s more than a platform—it’s a movement that 
                honors the vibrancy and strength of the martial arts ecosystem while fostering a sense of unity and 
                progress.
                </p>

                <Link    onClick={handleConfirmShow} className="action-btn">
                  <span>Buy RESPECT</span>
                </Link>
              </div>

             
            </div>
          </div>
          <div className="col-xl-7 col-md-12">
            <div className="banner__right" style={{ padding: "0" }}>
              <div className="image-1">
                <img src={imgFighter} width={800} alt="Cyfonii" />
              </div>

              <div
                className="banner-box"
                data-aos="fade-up"
                data-aos-duration="2000"
                style={{
                  width: "83%",
                  position: "absolute",
                  top: "330px",
                  backdropFilter: "none",
                }}
              >
                {/* <div
                  className="banner-box"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <h4 className="title">Next Price Increase</h4>
                </div> */}

                <div style={headingTextStyle}>
                  <h5 style={{paddingTop:"10px",paddingBottom:"10px",textAlign:"center",paddingLeft:"15px"}}>
                    Next phase pricing =  ${nextPrice.toFixed(3)}
                    </h5>
                    </div>

                <div className="top">
                  {/* <h4 className="title">Ninja 3D Digital Artwork</h4> */}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      borderBottom:
                        "border-bottom: 1px solid rgba(255, 255, 255, 0.1);",
                    }}
                  >
                    {/* <div id="mrd" style={headingBoxStyle}>
                      <h4 style={headingTextStyle} className="title">
                        Next phase pricing =  ${nextPrice.toFixed(3)}
                      </h4>
                      
                    </div> */}
                    
                    {/* <div style={headingBoxStyle2}>
                      
                      <ProgressBar
        now={progress} // Set the progress value
        // label={`${Math.round(progress)}%`} // Show percentage label insideeeEE
        variant="blue"
        className="w-100 h-100"
        style={{ height: '100px',margin:'0',borderRadius:'20px',boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset" }} // You can customize the color if you like
      />
     
     <h4 style={{position:'fixed',fontSize:'15px',color:'black'}} className="title">
     {((usdtToRaised / usdtTotalRaised) * 100).toFixed(2)} %
                      </h4>   
                      
                    </div> */}
                    <div className="done">
                      {/* Container for the boxes */}
                      <div className="countdown-box bnm" style={{height:'0px',background:'none',opacity:'0'}}>
                        {/* <span className="mn" style={numberStyle}>00</span>
                        <span className="mn" style={labelStyle}>DAY(S)</span> */}
                      </div>
                      <div className="countdown-box bnm" style={{height:'0px',background:'none',opacity:'0'}}>
                        {/* <span className="mn" style={numberStyle}>00</span>
                        <span className="mn" style={labelStyle}>HOUR(S)</span> */}
                      </div>
                      <div className="countdown-box bnm" style={{height:'0px',background:'none',opacity:'0'}}>
                        {/* <span className="mn" style={numberStyle}>
                          00
                        </span>
                        <span className="mn" style={labelStyle}>MINUTE(S)</span> */}
                      </div>
                      <div className="countdown-box bnm" style={{height:'0px',opacity:'0'}}>
                        {/* <span className="mn" style={numberStyle}>
                          00
                        </span>
                        <span className="mn" style={labelStyle}>SECOND(S)</span> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="wishlist">
                    <div className="icon">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.25 3.8125C11.25 2.36292 10.0256 1.1875 8.51533 1.1875C7.38658 1.1875 6.41708 1.84433 6 2.78175C5.58292 1.84433 4.61342 1.1875 3.48408 1.1875C1.975 1.1875 0.75 2.36292 0.75 3.8125C0.75 8.02417 6 10.8125 6 10.8125C6 10.8125 11.25 8.02417 11.25 3.8125Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p>3432</p>
                  </div> */}
                </div>

                <div className="main">
                  <div className="info" style={{ width: "37%" }}>
                    <p style={{ color: "#CED1D3",fontWeight:"600" , fontSize:"20px"}}>Current</p>
                    <h4>1 RESPECT</h4>
                    <h4 style={{ color: "#CED1D3" }}>${currentPrice}</h4>
                  </div>

                  <div style={containerStyle}>
                    <div style={countdownStyle} className="coundown">
                      <p style={{ color: "#fff" }}>
                        AMOUNT RAISED: ${usdtToRaised.toFixed(2)} 
                        {/* / $
                        {usdtTotalRaised.toFixed(2)} */}
                      </p>
                      <span
                        className="js-countdown"
                        data-timer="12865550"
                      ></span>
                    </div>

                    <div style={formContainerStyle}>
                      {activeCurrencry === "ETH"?
                      <div className="flex flex-col">
                      <div className='text-sm text-light-white'>Amount of ETH You Pay</div>
                      <div style={inputBoxStyle}>
                        
                      <input
                        type="text"
                        className="input-placeholder"
                        placeholder="Amount in you pay"
                        value={amount}
                        required
                        onChange={onAmountChange}
                        style={{
                          width: "100%",
                          background: "transparent",
                          border: "none",
                          outline: "none",
                          color: "#fff",
                        }}
                      />
                    </div></div>:<div className="flex flex-col">
                    <div className='text-sm text-light-white'>Amount of USDC You Pay</div>
                      <div style={inputBoxStyle}>
                        
                      <input
                        type="text"
                        className="input-placeholder"
                        placeholder="Amount in you pay"
                        value={amount}
                        required
                        onChange={onAmountChange}
                        style={{
                          width: "100%",
                          background: "transparent",
                          border: "none",
                          outline: "none",
                          color: "#fff",
                        }}
                      />
                    </div></div>}
                    <div className="flex flex-col">
                    <div className='text-sm text-light-white'>$RESPECT You Receive</div>
                      <div style={inputBoxStyleLast}>
                      
                        <input
                          className="input-placeholder"
                          type="text"
                          placeholder="Respect you get"
                          readOnly
                          value={youWillGet.toFixed(1)}
                          style={{
                            width: "100%",
                            background: "transparent",
                            border: "none",
                            outline: "none",
                            color: "#fff",
                          }}
                        />
                      </div>
                      </div>
                    </div>

                 
                  </div>
                </div>
                <div
                className="buts"
              >
                <button
                  onClick={() => {
                    setActiveCurrency("ETH");
                    setAmount(0);
                    setYouWillGet(0);
                  }}
                  style={{
                    width: "120px",
                    border: "1px solid #B5B9BE",
                    fontWeight: "600",
                    backgroundColor:'black',
                    padding:"5px",
                    py: "3px",
                    color: activeCurrencry === "ETH" ? "#fff" : "white",
                    background:
                      activeCurrencry === "ETH" ? "#000" : "grey",
                  }}
                >
                  <img
                    src={Ethereumlogo}
                    alt=""
                    srcSet=""
                    style={{
                      width: "30px",
                      background: "#D9D9D9",
                      borderRadius: "50%",
                      marginRight: "5px",
                    }}
                    
                  />
                  ETH
                </button>
                <button
                  onClick={() => {
                    setActiveCurrency("USDC");
                    setAmount(0);
                    setYouWillGet(0);
                  }}
                  style={{
                    width: "120px",
                    border: "1px solid #B5B9BE",
                    fontWeight: "600",
                    backgroundColor:'black',
                    padding:"5px",
                    py: "3px",
                    color: activeCurrencry === "USDC" ? "#fff" : "white",
                    background:
                      activeCurrencry === "USDC" ? "#000" : "grey",
                  }}
                >
                  <img
                    src={USDC}
                    alt=""
                    srcSet=""
                    style={{
                      width: "30px",
                      marginRight: "5px",
                      borderRadius: "50%",
                    }}
                  />
                  USDC
                </button>
              </div>     
              {address ? (
                <>
                  {activeCurrencry === "USDC" ? (
                    <>
                      {canbuy ? (
                        <div
                          // style={{
                          //   border: "1px solid #B5B9BE",
                          //   color: "#000",
                          //   fontWeight: "600",
                          //   py: 1,
                          //   width: "100%",
                          // }}
                          className="button"
                          onClick={buyHandler}
                        >
                             <Link
                    to="#"
                    className="action-btn"
                    data-toggle="modal"
                    data-target="#popup_bid"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span>
                    Buy With USDC
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 16.5L19 9M19 9L11.5 1.5M19 9H1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                          
                        </div>
                      ) : (
                        <div
                          // style={{
                          //   border: "1px solid #B5B9BE",
                          //   color: "#000",
                          //   fontWeight: "600",
                          //   py: 1,
                          //   width: "100%",
                          // }}
                          className="button"
                          onClick={approveUSDT}
                        >
                          <Link
                    to="#"
                    className="action-btn"
                    data-toggle="modal"
                    data-target="#popup_bid"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span>
                    Approve
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 16.5L19 9M19 9L11.5 1.5M19 9H1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                          
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      // style={{
                      //   border: "1px solid #B5B9BE",
                      //   color: "#000",
                      //   fontWeight: "600",
                      //   py: 1,
                      //   width: "100%",
                      // }}
                      onClick={buyHandler}
                      className="button"
                    >
                       <Link
                    to="#"
                    className="action-btn"
                    data-toggle="modal"
                    data-target="#popup_bid"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span>
                    Buy With {activeCurrencry}
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 16.5L19 9M19 9L11.5 1.5M19 9H1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                  {/* {error && (
    <Toast
      alertState={{ open: true, message: "Insufficient Balance", severity: "error" }}
      setAlertState={setAlertState}
    />
  )} */}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div
                onClick={handleConfirmShow}
                    className="button"
                    // style={{
                    //   border: "1px solid #B5B9BE",
                    //   color: "#000",
                    //   fontWeight: "600",
                    //   py: 1,
                    //   width: "40%",
                      
                    // }}
                  >
                    <Link
                    to="#"
                    className="action-btn"
                    data-toggle="modal"
                    data-target="#popup_bid"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span>
                      Connect Wallet{" "}
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 16.5L19 9M19 9L11.5 1.5M19 9H1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                  </div>
                </>
              )}
              {address && (
                <>
                  <div
                    onClick={connectionHandler}
                    className="button"
                    // style={{
                    //   border: "1px solid #B5B9BE",
                    //   color: "#000",
                    //   fontWeight: "600",
                    //   py: 1,
                    //   width: "100%",
                    // }}
                  >
                    <Link
                    to="#"
                    className="action-btn"
                    data-toggle="modal"
                    data-target="#popup_bid"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span>
                      Disconnect Wallet{" "}
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 16.5L19 9M19 9L11.5 1.5M19 9H1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                    {/* {address.slice(0, 5)} ... {address.slice(-5)} */}
                  </div>
                </>
              )}
              
                {/* <div className="button">
                  <Link
                    to="#"
                    className="action-btn"
                    data-toggle="modal"
                    data-target="#popup_bid"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span>
                      Connect Wallet{" "}
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 16.5L19 9M19 9L11.5 1.5M19 9H1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showVerifyModal}
        onHide={handleVerifyClose}
        size="lg"
        // Setting the width to 80% of the screen
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '15px', color: 'black',textAlign:'center',margin:'auto' }}>
            <span style={{textAlign:'center'}}>            MANDATORY PARTICIPATION AGREEMENT AND DISCLAIMER </span>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 'small', color: 'black', maxHeight: '70vh', // Max height to prevent body from overflowing
            overflowY: 'auto' }}>
          <p>
            <strong>IMPORTANT LEGAL NOTICE</strong><br />
            This Participation Declaration and Disclaimer governs access to, adopters, or participation in activities 
            related to the RESPECT Token. By proceeding, you acknowledge and agree to the terms outlined below. 
            Failure to comply may result in termination of participation and/or legal consequences.
          </p>
          <br/>
          <p>
            <strong>1. Restricted Participation</strong><br />
            Participation in the RESPECT Token is expressly prohibited for: Individuals or entities located in, resident 
            in, or otherwise subject to the jurisdiction of the United States of America or its territories and 
            possessions (the "U.S."); Individuals or entities in jurisdictions where cryptocurrency participation is 
            prohibited or restricted by applicable law. <br/>
            Restricted persons include but are not limited to: U.S. citizens, regardless of residency; U.S. residents, 
            including green card holders or tax residents; Entities incorporated or organized under U.S. law; Persons 
            accessing or transacting related to the RESPECT Token from within the U.S. or restricted jurisdictions.
          <br/>
          </p>
          <br/>

          <p>
            <strong>2. Declarations by Participants</strong><br />
            By participating, you represent, warrant, and agree that:
            <ul>
              <li><strong>Non-U.S. Person:</strong> You are not a U.S. Person as defined by Regulation S under the U.S. Securities Act of 1933, as amended.</li>
              <li><strong>Jurisdictional Compliance:</strong> Your participation complies with all applicable laws and regulations in your jurisdiction, and you are solely responsible for ensuring such compliance.</li>
              <li><strong>No Regulatory Protections:</strong> You understand that the RESPECT Token is not registered as a security, financial product, or investment.</li>
              <li><strong>Truthful Information:</strong> All information you provide, including residency and citizenship, is true, accurate, and complete.</li>
              <li><strong>Voluntary Participation:</strong> Your participation is entirely voluntary and does not rely on guarantees, warranties, or representations regarding future value, returns, or usability of the RESPECT Token.</li>
            </ul>
          </p>
          <br/>

          <p>
            <strong>3. Limitations and Waivers</strong><br />
            <ul>
              <li><strong>Non-Investment Token:</strong> The RESPECT Token is not an investment product and does not provide ownership, equity, or profit-sharing rights in any entity or organization.</li>
              <li><strong>No Liability:</strong> The issuer and associated parties are not liable for any losses, claims, damages, or liabilities arising from:
                <ul>
                  <li>Changes in legal or regulatory conditions;</li>
                  <li>Loss of token value or usability;</li>
                  <li>Misrepresentations made by participants regarding eligibility.</li>
                </ul>
              </li>
              <li><strong>Indemnification:</strong> You agree to indemnify and hold harmless the issuer, its affiliates, and agents against any claims or damages resulting from your participation or breach of these terms.</li>
            </ul>
          </p>
          <br/>

          <p>
            <strong>4. Risks and Disclaimers</strong><br />
            Participation in the RESPECT Token involves significant risks, including but not limited to:
            <ul>
              <li>Loss of funds due to token devaluation, theft, or technological vulnerabilities;</li>
              <li>Regulatory changes that could impact the legal status, usability, or value of the token;</li>
              <li>Irrevocable transactions on the blockchain.</li>
            </ul>
            Participants are solely responsible for evaluating these risks and accept them fully.
          </p>
          <br/>

          <p>
            <strong>5. Prohibited Conduct</strong><br />
            Participants agree not to:
            <ul>
              <li>Circumvent restrictions through intermediaries, false declarations, or deceptive practices;</li>
              <li>Engage in unlawful activities, including but not limited to money laundering, fraud, or terrorist financing, through the RESPECT Token;</li>
              <li>Resell or transfer tokens to restricted persons or jurisdictions.</li>
            </ul>
          </p>
          <br/>

          <p>
            <strong>6. Governing Law and Dispute Resolution</strong><br />
            <ul>
              <li><strong>Governing Law:</strong> This disclaimer is governed by the laws of the Republic of Singapore.</li>
              <li><strong>Dispute Resolution:</strong> All disputes arising from participation will be resolved through arbitration in Singapore under the rules of the Singapore International Arbitration Centre (SIAC).</li>
            </ul>
          </p>
          <br/>

          <p>
            <strong>7. Acknowledgment and Agreement</strong><br />
            By participating in the RESPECT Token, you confirm and agree to the following:
            <ul>
              <li>I have read, understood, and accepted the terms of this disclaimer.</li>
              <li>I am not a U.S. citizen, U.S. resident, or otherwise subject to U.S. jurisdiction.</li>
              <li>I understand that my participation is void where prohibited by law.</li>
              <li>I accept full responsibility for compliance with the laws of my jurisdiction.</li>
            </ul>
          </p>
          <br/>

          {/* <div style={{textAlign:'center'}}>
            <strong>PLEASE CONFIRM YOUR ELIGIBILITY TO PROCEED:</strong><br />
            <br />
            <Button variant="success" size="sm" onClick={handleConfirmShow}>
            I MEET ELIGIBILITY
            </Button>{' '}
            <Button variant="danger" size="sm" onClick={handleIneligibilityShow}>
              I DO NOT MEET  ELIGIBILITY 
            </Button>
          </div> */}
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal */}
      <Modal show={showConfirmationModal} size="lg" onHide={handleConfirmClose}>
  <Modal.Header style={{ borderBottom: 'none' }} closeButton closeVariant="black">
    <Modal.Title
      style={{
        fontSize: '18px',
        textAlign: 'center',
        margin: 'auto',
        width: '100%',
        fontWeight: 'bold',
        color: 'black',
      }}
    >
      CONNECT WALLET
    </Modal.Title>
  </Modal.Header>
  <Modal.Body style={{ color: 'black', padding: '20px' }}>
    <p
      style={{
        fontSize: '14px',
        textAlign: 'center',
        marginBottom: '20px',
        lineHeight: '1.5',
      }}
    >
      By connecting a wallet, you acknowledge that you have read, understood, and agree to{' '}
      <a onClick={handleVerifyShow} target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'underline' }}>
        Respect Token Terms of Service
      </a>.
    </p>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <div style={{ display: 'flex', alignItems: 'flex-start', gap: '10px' }}>
        <input
          type="checkbox"
          id="termsCheck"
          style={{ marginTop: '2px' }}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="termsCheck" style={{ fontSize: '14px', lineHeight: '1.5' }}>
          I have read and agree to terms and conditions.
        </label>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '30px',
        alignItems: 'center',
      }}
    >
      {/* MetaMask */}
      <div
        style={{
          textAlign: 'center',
          cursor: isChecked ? 'pointer' : 'not-allowed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={isChecked ? connectionHandler : null}
      >
        <img
          src={meta}
          alt="MetaMask"
          style={{
            width: '50px',
            height: '50px',
            marginBottom: '8px',
            opacity: isChecked ? 1 : 0.5, // Adjust opacity when disabled
          }}
        />
        <p style={{ fontSize: '10px', fontWeight: 'bold', margin: 0 }}>
          MetaMask
        </p>
      </div>

      {/* Trust Wallet */}
      <div
        style={{
          textAlign: 'center',
          cursor: isChecked ? 'pointer' : 'not-allowed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={isChecked ? connectionHandler : null}
      >
        <img
          src={trust}
          alt="Trust Wallet"
          style={{
            width: '50px',
            height: '50px',
            marginBottom: '8px',
            opacity: isChecked ? 1 : 0.5, // Adjust opacity when disabled
          }}
        />
        <p style={{ fontSize: '10px', fontWeight: 'bold', margin: 0 }}>
          Trust Wallet
        </p>
      </div>

      {/* Coinbase Wallet */}
      <div
        style={{
          textAlign: 'center',
          cursor: isChecked ? 'pointer' : 'not-allowed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={isChecked ? connectionHandler : null}
      >
        <img
          src={coin}
          alt="Coinbase Wallet"
          style={{
            width: '50px',
            height: '50px',
            marginBottom: '8px',
            opacity: isChecked ? 1 : 0.5, // Adjust opacity when disabled
          }}
        />
        <p style={{ fontSize: '10px', fontWeight: 'bold', margin: 0 }}>
          Coinbase Wallet
        </p>
      </div>

      {/* WalletConnect */}
      <div
        style={{
          textAlign: 'center',
          cursor: isChecked ? 'pointer' : 'not-allowed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={isChecked ? connectionHandler : null}
      >
        <img
          src={walletsd}
          alt="WalletConnect"
          style={{
            width: '50px',
            height: '50px',
            marginBottom: '8px',
            opacity: isChecked ? 1 : 0.5, // Adjust opacity when disabled
          }}
        />
        <p style={{ fontSize: '10px', fontWeight: 'bold', margin: 0 }}>
          WalletConnect
        </p>
      </div>
    </div>
  </Modal.Body>
  <Modal.Footer style={{ justifyContent: 'center', borderTop: 'none' }} />
</Modal>



      {/* Ineligibility Modal */}
      <Modal show={showIneligibilityModal} size="lg" onHide={handleIneligibilityClose}>
        <Modal.Header closeButton>
          <Modal.Title  style={{ fontSize: '15px', color: 'black',textAlign:'center',margin:'auto',width:'100%' }}> <span style={{textAlign:'center'}}>    Notice of Ineligibility</span></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 'small', color: 'black' }}>
        We regret to inform you that, based on your response, you are not eligible to participate in the RESPECT Token purchase or related activities.
<br/>
Participation in the RESPECT Token is restricted to individuals and entities that meet the eligibility requirements outlined in the Participation Declaration and Disclaimer. These requirements are in place to comply with applicable laws and regulations, including restrictions on participation from certain jurisdictions.
<br/>
Important:
Attempting to circumvent these restrictions by providing false or misleading information may result in legal consequences, including but not limited to disqualification from participation, forfeiture of tokens, or other actions as permitted by law.
<br/>
If you believe this determination was made in error, we recommend reviewing the eligibility requirements again or consulting legal counsel before proceeding further.
<br/>
We thank you for your understanding and cooperation.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleIneligibilityClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
    </>
  );
}

export default Banner3;
